import React, { useState, useEffect } from 'react';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import SummaryApi from '../common';
import backgroundImage from '../assest/sgu.webp';
import { BsPersonCircle } from "react-icons/bs";
import topLeftImage from '../assest/sgu_logo.png';
import topRightImage from '../assest/OAKYA.png';

const EmployeeLogin = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [otpSection, setOtpSection] = useState(false);
    const [otp, setOtp] = useState(["", "", "", ""]);
    const [sentOtp, setSentOtp] = useState("");
    const [otpExpired, setOtpExpired] = useState(false);
    const [otpTimer, setOtpTimer] = useState(null);
    const [data, setData] = useState({
        mobile_number: "",
        password: ""
    });
    const navigate = useNavigate();

    const handleOnChange = (e) => {
        const { name, value } = e.target;

        setData((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleOtpChange = (e, index) => {
        const newOtp = [...otp];
        newOtp[index] = e.target.value;
        setOtp(newOtp);
    
        const value = e.target.value;
    
        if (value !== "") {
            // Move to the next box if the current box is filled and it's not the last box
            if (index < otp.length - 1) {
                document.getElementById(`otp-input-${index + 1}`).focus();
            }
        } else if (value === "" && e.nativeEvent.inputType === "deleteContentBackward") {
            // Move to the previous box when backspace is pressed and the current box is empty
            if (index > 0) {
                document.getElementById(`otp-input-${index - 1}`).focus();
            }
        }
    };
    const sendOtp = (mobile_number) => {
        const generatedOtp = Math.floor(1000 + Math.random() * 9000).toString();
        setSentOtp(generatedOtp);  // Set the generated OTP
        setOtpExpired(false);      // Reset the OTP expiration status
    
        const encodedOtp = encodeURIComponent(generatedOtp);
        const smsUrl = `https://sms.nettyfish.com/api/v2/SendSMS?SenderId=OAKYA&TemplateId=1707173313269381321&Message=Dear%20User%20your%20OTP%20${generatedOtp}%20for%20accessing%20admin%20panel%20-Oakya&PrincipleEntityId=1701172795416066262&ApiKey=ktTxImpILf3IA3lAdLt3sxLwpf0GUx41h9y%2BhU1LaWU=&ClientId=12eae6c0-7738-4f2a-afbe-77888ae20d0f&MobileNumbers=${mobile_number}`;
    
        toast.info("OTP sent to your registered mobile number.");
    
        // Send the OTP request using GET method
        fetch(smsUrl, {
            method: 'GET',
            mode: 'no-cors',  // Use 'no-cors' to bypass CORS restrictions
        })
        .then(response => {
            console.log('OTP request sent');
        })
        .catch((error) => {
            toast.error('Error sending OTP: ' + error);
        });
    
        // Set a timer to expire the OTP after 2 minutes (120,000 milliseconds)
        const timer = setTimeout(() => {
            setOtpExpired(true); // Mark OTP as expired after 2 minutes
            toast.error("OTP has expired. Please request a new OTP.");
        }, 2 * 60 * 1000);  // 2 minutes timeout
    
        // Store the timer so it can be cleared when the component unmounts or if needed
        setOtpTimer(timer);
    };
    
    // Use Effect hook to clear the timer on component unmount
    useEffect(() => {
        return () => {
            if (otpTimer) clearTimeout(otpTimer); // Clear the timer when the component unmounts
        };
    }, [otpTimer]);
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(SummaryApi.verifyEmployee.url, {
                method: SummaryApi.verifyEmployee.method,
                headers: {
                    'Authorization': 'Bearer sandeep_university_916',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    mobile_number: data.mobile_number,
                    password: data.password
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to verify mobile number');
            }

            const responseData = await response.json();

            if (responseData.status) {
                sessionStorage.setItem('H7Z4L9VD2XK5JW8QRYT6', btoa(responseData.employee_id));
                sessionStorage.setItem('K3M8X1JQ5VZ4B7PWTL9', btoa(responseData.member_type));

                if (responseData.member_type === "Admin") {
                    setOtpSection(true);
                    sendOtp(data.mobile_number); // Send OTP for Admin users
                } else {
                    navigate('/');
                }
            } else {
                toast.error(responseData.message);
            }
        } catch (error) {
            toast.error('Failed to check mobile number existence');
        }
    };

    const handleOtpSubmit = (e) => {
        e.preventDefault();

        if (otpExpired) {
            toast.error("OTP has expired. Please request a new OTP.");
            return;
        }

        const enteredOtp = otp.join(""); // Combine the OTP values from the 4 boxes
        if (enteredOtp === sentOtp) {
            toast.success("OTP verified successfully!");
            navigate('/');
        } else {
            toast.error("Invalid OTP entered.");
        }
    };

    useEffect(() => {
        return () => {
            if (otpTimer) clearTimeout(otpTimer);
        };
    }, [otpTimer]);

    return (
        <section id='login' className="flex flex-col min-h-screen"
            style={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'bottom',
                height: '100vh'
            }}>

            {/* Header */}
            <header className="bg-transparent text-black py-4 flex flex-col items-center">
                <div className="flex justify-between w-full px-4">
                    <div className="flex items-center">
                        <img src={topLeftImage} alt="Logo 1" className="h-16 sm:h-20" />
                    </div>
                    <div className="text-center flex-grow ml-4 mt-2">
                        <div className='relative mx-auto max-w-[1100px]'>
                            <span className='relative z-10 text-2xl sm:text-3xl lg:text-5xl font-extrabold text-black'
                                style={{ textShadow: '-5px 0px 0 rgba(255, 255, 255, 1)' }}>
                                SANJAY GHODAWAT UNIVERSITY, Kolhapur
                            </span>
                        </div>
                        <div className='mx-auto max-w-[900px]'>
                            <span className='text-sm sm:text-lg lg:text-2xl font-bold'>GAT NO 583 TO 585, A/P.ATIGRE TALUKA:HATKANANGALE DIST-KOLHAPUR, STATE-MAHARASHTRA, INDIA</span>
                        </div>
                    </div>
                    <div className='flex items-center'>
                        <img src={topRightImage} alt="Logo 2" className="h-16" />
                    </div>
                </div>
            </header>

            {/* Main Login Form */}
            <div className="absolute inset-0 flex mt-[100px] items-center justify-center">
                <div className='bg-[#fffcdc] p-5 w-full max-w-xs sm:max-w-sm mx-auto shadow-lg rounded-lg'>
                    <div className='w-16 h-16 mx-auto sm:w-20 sm:h-20'>
                        <BsPersonCircle className='w-full h-full' />
                    </div>

                    <h2 className='text-center text-xl sm:text-2xl mb-4'>Employee Login</h2>
                    <p className='text-xs sm:text-sm text-center mb-2'>Please enter your Mobile number and Password to access your account.</p>

                    {!otpSection ? (
                        <form className='pt-6 flex flex-col gap-2' onSubmit={handleSubmit}>
                            <div className='grid'>
                                <label className='text-sm'>Mobile Number:</label>
                                <div className='border-black rounded-lg border p-2'>
                                    <input
                                        type='tel'
                                        placeholder='Enter mobile number'
                                        name='mobile_number'
                                        value={data.mobile_number}
                                        onChange={handleOnChange}
                                        className='w-full h-full outline-none bg-transparent'
                                        required
                                    />
                                </div>
                            </div>

                            <div>
                                <label className='text-sm'>Password:</label>
                                <div className='border border-black rounded-lg p-2 flex'>
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        placeholder='Enter password'
                                        value={data.password}
                                        name='password'
                                        onChange={handleOnChange}
                                        className='w-full h-full outline-none bg-transparent'
                                        required
                                    />
                                    <div className='cursor-pointer text-xl' onClick={() => setShowPassword((prev) => !prev)}>
                                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                                    </div>
                                </div>
                            </div>

                            <button className='bg-red-600 hover:bg-red-700 text-white px-4 py-2 w-full max-w-[150px] rounded-full hover:scale-110 transition-all mx-auto block mt-6'>
                                Login
                            </button>
                        </form>
                    ) : (
                        <form className='pt-6 flex flex-col gap-2' onSubmit={handleOtpSubmit}>
                        <div>
                            <label className='text-sm'>Enter OTP:</label>
                            <div className='grid grid-cols-4 gap-2'>
                                {otp.map((digit, index) => (
                                    <input
                                        key={index}
                                        id={`otp-input-${index}`} // Add unique ID for each input
                                        type='text'
                                        maxLength='1'
                                        value={digit}
                                        onChange={(e) => handleOtpChange(e, index)}
                                        className='w-full h-full outline-none text-center border border-black rounded-lg p-2'
                                        required
                                        autoFocus={index === 0} // Focus the first input on load
                                    />
                                ))}
                            </div>
                        </div>
                    
                        <button className='bg-red-600 hover:bg-red-700 text-white px-4 py-2 w-full max-w-[150px] rounded-full hover:scale-110 transition-all mx-auto block mt-6'>
                            Verify OTP
                        </button>
                    </form>
                    )}
                </div>
            </div>
            {/* Footer */}
            <div className='absolute bottom-16 right-12'>
                <div className='flex flex-col text-white'>
                    <span className='text-lg sm:text-2xl'>Developed By</span>
                    <span className='font-bold text-lg sm:text-2xl'>OAKYA IT SERVICES PVT LTD</span>
                </div>
            </div>
        </section>
    );
};

export default EmployeeLogin;
