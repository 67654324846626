//const backendDomain = "http://192.168.1.138/sgu_erp_live"
// const backendDomain = "https://erp.sanjayghodawat.co.in/api"
const backendDomain = "https://sguerp.oakyaitservices.in/api" 
const SummaryApi = {
    uploadEmployee : {
        url : `${backendDomain}/employee/create.php`,
        method : "post"
    },
    verifyEmployee:{
        url : `${backendDomain}/employee/login.php`,
        method : "post"
    },
    verifyOtp:{
        url : `${backendDomain}/employee/login.php`,
        method : "put" 
    },
    uploadStudent:{
        url : `${backendDomain}/students/create.php`,
        method : "post"
    },
    updateStudent:{
        url : `${backendDomain}/students/update.php`,   
        method : "post"
    },
    getStudentsByEmployeeID:{
        url : `${backendDomain}/students/retrieve.php`,
        method : "put"
    },
    getAllStudents:{
        url : `${backendDomain}/students/retrieve.php`,
        method : "get"
    },
    getAllEmployees:{
        url : `${backendDomain}/employee/retrieve.php`,
        method : "get"
    },
    getAllConsultants:{
        url : `${backendDomain}/employee/consultant.php`,
        method : "get"
    },
    changeEmployeePassword:{
        url : `${backendDomain}/employee/update.php`,
        method : "post"
    },
    getEmployeeById:{
        url : `${backendDomain}/employee/retrieve.php`,
        method : "post"
    },
    activateEmployee:{
        url : `${backendDomain}/employee/update.php`,
        method : "put"
    },
    excelUpload:{
        url : `${backendDomain}/student_data/create.php`,
        method : "post"
    },
    getEmployeeStudentData:{
        url : `${backendDomain}/employee_student_data/retrieve.php`,
        method : "post"
    },
    bulkAction: {
        url : `${backendDomain}/employee/insert.php`,
        method : "post"
    },
    getAllInterestedStudents: {
        url : `${backendDomain}/students/interested.php`,
        method : "get"
    },
    interestedStudents: {
        url : `${backendDomain}/students/interested.php`,
        method : "post"
    },
    notInterestedStudents: {
        url : `${backendDomain}/students/not_interested.php`,
        method : "post"
    },
    getAllNotInterestedStudents: {
        url : `${backendDomain}/students/not_interested.php`,
        method : "get"
    },
    mostInterestedStudents: {
        url : `${backendDomain}/students/most_interested.php`,
        method : "post"
    },
    getAllMostInterestedStudents: {
        url : `${backendDomain}/students/most_interested.php`,
        method : "get"
    },
    employeeReport: {
        url : `${backendDomain}/employee/employee_student.php`,
        method : "get"
    }
}

export default SummaryApi;