// data.js - Replace static data with fetched data from API using POST method

import SummaryApi from "../common";

// Function to fetch student data from the API (with POST method)
const fetchStudentDataFromAPI = async () => {
  try {
    // Get the encoded employee ID from sessionStorage and decode it
    const encodedEmployeeId = sessionStorage.getItem('H7Z4L9VD2XK5JW8QRYT6');
    const decodedEmployeeId = atob(encodedEmployeeId); // Decode the employee ID
    
    // Check if decodedEmployeeId exists, else handle error
    if (!decodedEmployeeId) {
      throw new Error('Employee ID not found in sessionStorage');
    }

    // Make an API call with POST method
    const response = await fetch(SummaryApi.getEmployeeStudentData.url, {
      method: SummaryApi.getEmployeeStudentData.method, // Use POST method
      headers: {
        'Authorization': 'Bearer sandeep_university_916',
        'Content-Type': 'application/json', // Ensure that we're sending JSON
      },
      body: JSON.stringify({ employee_id: decodedEmployeeId }), // Send decoded employee_id in the body
    });

    // Check if the response was successful (status 200-299)
    if (!response.ok) {
      throw new Error('Failed to fetch data from API');
    }

    // Parse the response as JSON
    const data = await response.json();

    // Check if the API response has a `status` property and is true
    if (data.status === true) {
      return data.data; // Return the data (an array of students)
    } else {
      throw new Error('API responded with status false');
    }
  } catch (error) {
    console.error('Error fetching student data:', error);
    return []; // Return an empty array in case of an error
  }
};

// Export the fetch function that returns a promise with the data
export const getStudentsData = async () => {
  return await fetchStudentDataFromAPI(); // Return the data fetched from the API
};
