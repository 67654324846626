import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FaRegCircleUser } from "react-icons/fa6";
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { Button, Layout, Menu, theme } from 'antd';
import { toast } from 'react-toastify';
import scrollTop from '../helpers/scrollTop';
import { RiDashboardFill } from "react-icons/ri";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import SummaryApi from '../common';
import { FaUser } from "react-icons/fa";
import { BiSolidUserDetail } from "react-icons/bi";

const { Header, Content } = Layout;

const AdminPanel = () => {
    const user = useSelector(state => state?.user?.user);
    const navigate = useNavigate();
    const location = useLocation(); // Get the current location
    const [memberType, setMemberType] = useState(null);
    const [employeeFullName, setEmployeeFullName] = useState('');
    const { token } = theme.useToken();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchMemberType = async () => {
            setLoading(true);
            try {
                const encodedMemberType = sessionStorage.getItem('K3M8X1JQ5VZ4B7PWTL9');
                if (encodedMemberType) {
                    const decodedMemberType = atob(encodedMemberType);
                    setMemberType(decodedMemberType);
                } else {
                    navigate('/employee-login');
                }
            } catch (error) {
                toast.error(`Error: ${error.message}`);
                navigate('/employee-login');
            } finally {
                setLoading(false);
            }
        };

        const fetchEmployeeDetails = async () => {
            try {
                const encodedEmployeeId = sessionStorage.getItem('H7Z4L9VD2XK5JW8QRYT6');
                if (encodedEmployeeId) {
                    const decodedEmployeeId = atob(encodedEmployeeId);
                    const response = await fetch(SummaryApi.getEmployeeById.url, {
                        method: SummaryApi.getEmployeeById.method,
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer sandeep_university_916',
                        },
                        body: JSON.stringify({ employee_id: decodedEmployeeId }),
                    });

                    if (!response.ok) {
                        throw new Error('Failed to fetch employee data');
                    }

                    const employeeData = await response.json();
                    setEmployeeFullName(employeeData?.data?.full_name);
                }
            } catch (error) {
                toast.error(`Error fetching employee details: ${error.message}`);
            }
        };
        fetchMemberType();
        fetchEmployeeDetails();
    }, [navigate]);

    const handleLogout = () => {
        sessionStorage.removeItem('H7Z4L9VD2XK5JW8QRYT6');
        sessionStorage.removeItem('K3M8X1JQ5VZ4B7PWTL9');
        navigate('/employee-login');
    };

    const menuItems = [
        { key: '', icon: <RiDashboardFill />, label: 'Dashboard' },
    
        // These are accessible to all users except Admin or Manager
        ...(memberType !== 'Admin' && memberType !== 'Manager' ? [{ key: 'today-task', icon: <BiSolidUserDetail />, label: "Today's Task" }] : []),
        ...(memberType !== 'Admin' && memberType !== 'Manager' ? [{ key: 'enroll-student', icon: <BiSolidUserDetail />, label: 'Enroll Student' }] : []),
    
        // These are accessible to all users
        { key: 'enrolled-students', icon: <AiOutlineUsergroupAdd />, label: 'Total Enrolled Students' },
        { key: 'interested-students', icon: <AiOutlineUsergroupAdd />, label: 'Interested Students' },
        { key: 'not-interested-students', icon: <AiOutlineUsergroupAdd />, label: 'Not Interested Students' },
        { key: 'most-interested-students', icon: <AiOutlineUsergroupAdd />, label: 'Most Interested Students' },
    
        // These are accessible to both Admin and Manager
        ...(memberType === 'Admin' ? [{ key: 'excel-upload', icon: <AiOutlineUsergroupAdd />, label: 'Student Excel Upload' }] : []),
        ...(['Admin', 'Manager'].includes(memberType) ? [{ key: 'all-employees', icon: <AiOutlineUsergroupAdd />, label: 'All Employees' }] : []),
        // ...(['Admin', 'Manager'].includes(memberType) ? [{ key: 'all-consultants', icon: <AiOutlineUsergroupAdd />, label: 'All Consultants' }] : []),
        ...(['Admin', 'Manager'].includes(memberType) ? [{ key: 'employee-task-report', icon: <AiOutlineUsergroupAdd />, label: 'Employee Task Report' }] : []),
        // This is available to all users
        { key: 'search-students', icon: <AiOutlineUsergroupAdd />, label: 'Search Students' },
    ];
    

    // Determine the active key based on the current path
    const selectedKey = menuItems.find(item => location.pathname === `/${item.key}` || (item.key === '' && location.pathname === '/'))?.key || '';

    // Function to handle right-click event on the menu items
    const handleRightClick = (e, url) => {
        e.preventDefault();  // Prevent the default right-click menu
        window.open(url, '_blank');  // Open the URL in a new tab
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            {/* Admin Panel Sidebar */}
            <div style={{
                width: '250px',
                background: '#001529',
                color: 'white',
                position: 'fixed',
                height: '100vh',
                zIndex: 1000,
                paddingTop: '30px',
            }}>
                <div className='flex flex-col items-center p-4'>
                    <div className='text-3xl cursor-pointer'>
                        {user?.profilePic ? (
                            <img src={user?.profilePic} className='w-12 h-12 rounded-full' alt={user?.name} />
                        ) : (
                            <FaRegCircleUser className='text-white text-2xl' />
                        )}
                    </div>
                    <p className='text-sm font-semibold text-white mt-2 uppercase'>
    {memberType === 'Manager' ? 'Chief operational manager' : memberType}
</p>

                </div>
                <Menu
                    theme="dark"
                    mode="inline"
                    selectedKeys={[selectedKey]} // Set the active key based on the current path
                    onClick={({ key }) => {
                        navigate(key);
                        scrollTop(); // Ensure scrolling to top on navigation
                    }}
                    items={menuItems.map(item => ({
                        key: item.key,
                        icon: item.icon,
                        label: (
                            <Link
                                to={item.key}
                                onClick={scrollTop}
                                onContextMenu={(e) => handleRightClick(e, `/${item.key}`)} // Attach right-click functionality
                            >
                                {item.label}
                            </Link>
                        ),
                    }))}
                />
            </div>

            {/* Main Content */}
            <Layout style={{ marginLeft: '250px', minHeight: '100vh' }}>
                <Header
                    className="site-layout-background"
                    style={{
                        padding: 0,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '0 16px',
                        background: '#001529',
                        position: 'sticky',
                        top: 0,
                        zIndex: 1000,
                    }}
                >
                    <div className="header-title" style={{ color: 'white' }}>
                        <h1 className="text-lg font-bold">SANJAY GHODAWAT UNIVERSITY ERP</h1>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', color: 'white', marginRight: '16px' }}>
                        <h1 className='mr-3'>
                            <span className="flex items-center capitalize">
                                <FaUser style={{ marginRight: '8px' }} />
                                {employeeFullName}
                            </span>
                        </h1>
                        <Button
                            type="text"
                            onClick={handleLogout}
                            style={{
                                backgroundColor: '#dc2626',
                                color: 'white',
                                border: 'none',
                                padding: '8px 16px',
                                borderRadius: '4px',
                                cursor: 'pointer',
                                transition: 'background-color 0.3s',
                            }}
                            onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#b91c1c'}
                            onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#dc2626'}
                        >
                            Logout
                        </Button>
                    </div>
                </Header>

                <Content
                    style={{
                        margin: '20px 16px',
                        padding: 24,
                        minHeight: 280,
                        background: token.colorBgContainer,
                        borderRadius: token.borderRadiusLG,
                    }}
                >
                    <Outlet />
                </Content>
            </Layout>
        </Layout>
    );
};

export default AdminPanel;
