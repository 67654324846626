import React, { useEffect, useState } from 'react';
import { useTable, usePagination } from 'react-table';
import { toast } from 'react-toastify';
import SummaryApi from '../common';
import { useNavigate } from 'react-router-dom';
import { FaUserGraduate, FaCalendarCheck, FaStar, FaRegFrown, FaRegFrownOpen, FaRocketchat } from 'react-icons/fa';
import Modal from 'react-modal';
import { Pie, Line, Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const navigate = useNavigate();
  const [overallCounts, setOverallCounts] = useState({
    totalStudents: 0,
    studentsCreatedToday: 0,
    interested: 0,
    notInterested: 0,
    mostInterested: 0,
    interestedAddedToday: 0,
    notInterestedAddedToday: 0,
    mostInterestedAddedToday: 0,
  });
  const [studentData, setStudentData] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [memberType, setMemberType] = useState(null);
  const [enrollmentData, setEnrollmentData] = useState({});
  const [callStatusCounts, setCallStatusCounts] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const encodedMemberType = sessionStorage.getItem('K3M8X1JQ5VZ4B7PWTL9');
        const memberType = encodedMemberType ? atob(encodedMemberType) : null;
        setMemberType(memberType);

        let response;

        if (memberType === 'Admin' || memberType === 'Manager') {
          response = await fetch(SummaryApi.getAllStudents.url, {
            method: SummaryApi.getAllStudents.method,
            headers: {
              'Authorization': 'Bearer sandeep_university_916',
              'Content-Type': 'application/json',
            },
          });
        } else {
          const encodedEmployeeId = sessionStorage.getItem('H7Z4L9VD2XK5JW8QRYT6');
          if (!encodedEmployeeId) {
            throw new Error('Employee ID not found in session storage');
          }
          const decodedEmployeeId = atob(encodedEmployeeId);

          response = await fetch(SummaryApi.getStudentsByEmployeeID.url, {
            method: SummaryApi.getStudentsByEmployeeID.method,
            headers: {
              'Authorization': 'Bearer sandeep_university_916',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ employee_id: decodedEmployeeId }),
          });
        }

        if (!response.ok) {
          throw new Error('Failed to fetch data from the endpoint');
        }

        const data = await response.json();
        const fetchedStudents = data?.data || [];

        // Calculate today's date
        const today = new Date().toISOString().split('T')[0];

        // Filter to get today's students
        const todayStudents = fetchedStudents.filter(student => student.created_date.startsWith(today));

        const calculateCallStatusCounts = (students) => {
          const counts = {};
          students.forEach(student => {
            const status = student.call_status; // Adjust this based on your actual field name
            counts[status] = (counts[status] || 0) + 1;
          });
          setCallStatusCounts(counts);
        };

        calculateCallStatusCounts(fetchedStudents);

        setOverallCounts({
          totalStudents: fetchedStudents.length,
          studentsCreatedToday: todayStudents.length,
          interested: fetchedStudents.filter(student => student.is_interested === "Interested").length,
          notInterested: fetchedStudents.filter(student => student.is_interested === "Not Interested").length,
          mostInterested: fetchedStudents.filter(student => student.is_interested === "Most Interested").length,
          interestedAddedToday: todayStudents.filter(student => student.is_interested === "Interested").length,
          notInterestedAddedToday: todayStudents.filter(student => student.is_interested === "Not Interested").length,
          mostInterestedAddedToday: todayStudents.filter(student => student.is_interested === "Most Interested").length,
        });

        // Set only today's students
        setStudentData(todayStudents);
        calculateEnrollmentData(fetchedStudents);

      } catch (error) {
        console.error('Error fetching data', error);
      } finally{
        setLoading(false);
      }
    };

    fetchData();
  }, []);



  const calculateEnrollmentData = (students) => {
    const enrollments = {};
    const today = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setDate(today.getDate() - 30);

    students.forEach(student => {
      const enrollmentDate = new Date(student.created_date);
      if (enrollmentDate >= oneMonthAgo && enrollmentDate <= today) {
        const dateString = enrollmentDate.toISOString().split('T')[0];
        enrollments[dateString] = (enrollments[dateString] || 0) + 1;
      }
    });

    setEnrollmentData(enrollments);
  };

  const data = React.useMemo(() => studentData, [studentData]);

  // Column definitions
  const columns = React.useMemo(() => {
    const cols = [
      memberType === 'Admin' && {
        Header: 'Employee Name',
        accessor: 'employee_name',
      },
      {
        Header: 'Enrollment Code',
        accessor: 'enrollment_code',
      },
      {
        Header: 'Student Name',
        accessor: 'student_name',
      },
      {
        Header: 'City',
        accessor: 'city',
      },
      {
        Header: 'Mobile',
        accessor: 'mobile_number1',
      },
      {
        Header: 'Enrolled Date',
        accessor: 'created_date',
        Cell: ({ value }) => new Date(value).toLocaleDateString('en-IN'),
      },
      {
        Header: 'Status',
        accessor: 'is_interested',
        Cell: ({ value }) => {
          let colorClass = 'text-gray-600'; // Default to red (Not Interested)
          if (value === 'Most Interested') {
            colorClass = 'text-green-600'; // Green for Most Interested
          } else if (value === 'Interested') {
            colorClass = 'text-yellow-500'; // Yellow for Interested
          } else if (value === 'Not Interested') {
            colorClass = 'text-red-600'; // Yellow for Interested
          }
        
          return (
            <span className={`font-semibold ${colorClass}`}>
              {value}
            </span>
          );
        },
        
      },
      {
        Header: 'View',
        Cell: ({ row }) => (
          <button
            onClick={() => openModal(row.original)}
            className="bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600"
          >
            View
          </button>
        ),
      },
    ];
    return cols.filter(Boolean);
  }, [memberType]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { pageIndex, pageSize },
    gotoPage,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    setPageSize,
    page,
    pageCount,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 5 },
    },
    usePagination
  );

  const openModal = (student) => {
    setSelectedStudent(student);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedStudent(null);
  };

  const pieData = {
    labels: ['Interested', 'Not Interested', 'Most Interested'],
    datasets: [{
      data: [overallCounts.interested, overallCounts.notInterested, overallCounts.mostInterested],
      backgroundColor: ['#36A2EB', '#FF6384', '#FF9F40'],
    }],
  };

  // Line chart data
  // Assuming enrollmentData is an object with date keys and enrollment counts as values
  const sortedDates = Object.keys(enrollmentData).sort((a, b) => new Date(a) - new Date(b));

  const sortedData = sortedDates.map(date => enrollmentData[date]);

  const lineData = {
    labels: sortedDates,
    datasets: [{
      label: 'Daily Enrollments',
      data: sortedData,
      fill: true,
      backgroundColor: 'rgba(153, 102, 255, 0.2)',
      borderColor: 'rgba(153, 102, 255, 1)',
      tension: 0.1,
    }],
  };

  // Bar chart data
  const barData = {
    labels: Object.keys(callStatusCounts),
    datasets: [{
      label: 'Call Status Count',
      data: Object.values(callStatusCounts),
      backgroundColor: 'rgba(75, 192, 192, 0.6)',
      borderColor: 'rgba(75, 192, 192, 1)',
      borderWidth: 1,
    }],
  };

  return (

    <div className="md:p-6 p-4 min-h-screen">
      {loading ? (
        <div className="fixed inset-0 bg-black bg-opacity-70 z-20 flex justify-center items-center">
          <span className="text-white text-lg">Fetching Data...</span>
        </div>
      ) : (
        <>
          <div>
      <h1 className='text-2xl font-bold mb-6 text-gray-800'>
        <span className='text-red-600'>SANJAY GHODAWAT UNIVERSITY</span>/Dashboard
      </h1>

      {/* Summary Section */}
      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6'>
        {/* Total Students Enrolled */}
        <div className='bg-blue-100 p-4 rounded-lg shadow-lg flex items-center'>
          <FaUserGraduate className='text-blue-600 text-3xl mr-4' />
          <div>
            <h3 className='text-lg font-semibold text-blue-700'>Total Students Enrolled</h3>
            <p className='text-xl font-bold text-blue-800'>{overallCounts.totalStudents}</p>
          </div>
        </div>

        {/* Total Interested Students */}
        <div className='bg-yellow-100 p-4 rounded-lg shadow-lg flex items-center'>
          <FaStar className='text-yellow-600 text-3xl mr-4' />
          <div>
            <h3 className='text-lg font-semibold text-yellow-700'>Total Interested Students</h3>
            <p className='text-xl font-bold text-yellow-800'>{overallCounts.interested}</p>
          </div>
        </div>
        {/*Total Not Interested Students */}
        <div className='bg-gray-100 p-4 rounded-lg shadow-lg flex items-center'>
          <FaRegFrown className='text-gray-600 text-3xl mr-4' /> {/* You can choose another icon here */}
          <div>
            <h3 className='text-lg font-semibold text-gray-700'>Total Not Interested Students</h3>
            <p className='text-xl font-bold text-gray-800'>{overallCounts.notInterested}</p>
          </div>
        </div>
        
        {/*Total Most Interested Students */}
        <div className='bg-teal-100 p-4 rounded-lg shadow-lg flex items-center'>
          <FaStar className='text-teal-600 text-3xl mr-4' />
          <div>
            <h3 className='text-lg font-semibold text-teal-700'>Total Most Interested Students</h3>
            <p className='text-xl font-bold text-teal-800'>{overallCounts.mostInterested}</p>
          </div>
        </div>

        {/* Students Enrolled Today */}
        <div className='bg-green-100 p-4 rounded-lg shadow-lg flex items-center'>
          <FaCalendarCheck className='text-green-600 text-3xl mr-4' />
          <div>
            <h3 className='text-lg font-semibold text-green-700'>Students Enrolled Today</h3>
            <p className='text-xl font-bold text-green-800'>{overallCounts.studentsCreatedToday}</p>
          </div>
        </div>

        {/* Interested Students Enrolled Today */}
        <div className='bg-red-100 p-4 rounded-lg shadow-lg flex items-center'>
          <FaStar className='text-red-600 text-3xl mr-4' />
          <div>
            <h3 className='text-lg font-semibold text-red-700'>Interested Students Enrolled Today</h3>
            <p className='text-xl font-bold text-red-800'>{overallCounts.interestedAddedToday}</p>
          </div>
        </div>

        {/* Not Interested Added Today */}
        <div className='bg-purple-100 p-4 rounded-lg shadow-lg flex items-center'>
          <FaRegFrownOpen className='text-purple-600 text-3xl mr-4' /> {/* You can choose another icon here */}
          <div>
            <h3 className='text-lg font-semibold text-purple-700'>Not Interested Added Today</h3>
            <p className='text-xl font-bold text-purple-800'>{overallCounts.notInterestedAddedToday}</p>
          </div>
        </div>

        {/* Most Interested Added Today */}
        <div className='bg-indigo-100 p-4 rounded-lg shadow-lg flex items-center'>
          <FaRocketchat className='text-indigo-600 text-3xl mr-4' /> {/* You can choose another icon here */}
          <div>
            <h3 className='text-lg font-semibold text-indigo-700'>Most Interested Added Today</h3>
            <p className='text-xl font-bold text-indigo-800'>{overallCounts.mostInterestedAddedToday}</p>
          </div>
        </div>
      </div>


      {/* Student Details Table */}
      <div className='bg-white border p-4 rounded-lg shadow-lg mb-6'>
        <h2 className='text-lg font-semibold mb-2 text-gray-700'>Today Enrolled Student Details</h2>
        <div className='overflow-x-auto'>
          <table {...getTableProps()} className='min-w-full bg-white mb-4'>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()} className='bg-gray-200 text-gray-600'>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps()} className='py-2 px-4 border-b text-left text-sm font-medium'>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.length > 0 ? (
                page.map(row => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} className='border-b'>
                      {row.cells.map(cell => (
                        <td {...cell.getCellProps()} className='py-2 px-4 text-sm'>
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={columns.length} className='text-center py-2 text-gray-600'>No students found for today.</td>
                </tr>
              )}
            </tbody>
          </table>

          {/* Pagination Controls */}
          <div className="flex justify-between items-center mb-4">
            <span>
              Page {pageIndex + 1} of {pageCount} of {overallCounts.studentsCreatedToday} entries
            </span>
            <div>
              <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className="px-3 py-1 border rounded">{"<<"}</button>
              <button onClick={previousPage} disabled={!canPreviousPage} className="px-3 py-1 border rounded">{"<"}</button>
              <button onClick={nextPage} disabled={!canNextPage} className="px-3 py-1 border rounded">{">"}</button>
              <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} className="px-3 py-1 border rounded">{">>"}</button>
            </div>
            <select value={pageSize} onChange={e => {
              setPageSize(Number(e.target.value));
            }} className="ml-4 border rounded">
              {[5, 10, 20, 50, 100].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      {/* Charts Section */}
      <div className='flex flex-wrap justify-between'>
        <div className="my-6 w-1/3 p-2">
          <h2 className="text-xl font-bold text-center text-blue-600">Call Status Distribution</h2>
          <div className="flex justify-center">
            <div className="h-96 w-full max-w-2xl">
              <Bar
                data={barData}
                options={{
                  maintainAspectRatio: false,
                  responsive: true,
                  plugins: {
                    legend: {
                      position: 'top',
                    },
                  },
                  scales: {
                    y: {
                      beginAtZero: true,
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
        <div className="my-6 w-full md:w-1/3 p-2">
          <h2 className="text-xl font-bold text-center text-blue-600">Daily Enrollments (Last 30 Days)</h2>
          <div className="flex justify-center">
            <div className="h-96 w-full max-w-2xl">
              <Line
                data={lineData}
                options={{
                  maintainAspectRatio: false,
                  responsive: true,
                  plugins: {
                    legend: {
                      position: 'top',
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>

        <div className="my-6 w-full md:w-1/3 p-2">
          <h2 className="text-xl font-bold text-center text-blue-600">Not Interested vs. Interested vs. Most Interested Students</h2>
          <div className="flex justify-center">
            <div className="h-96 w-full max-w-md">
              <Pie
                data={pieData}
                options={{
                  maintainAspectRatio: false,
                  responsive: true,
                  plugins: {
                    legend: {
                      position: 'top',
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>


      {/* Modal for Student Details */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Student Details"
        className="mx-auto flex flex-col my-10 max-h-[80vh] mt-[100px] max-w-[30%] bg-white rounded-lg shadow-lg overflow-auto"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="sticky top-0 z-10 bg-gray-100 border-b border-gray-300 px-8 py-4">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-semibold text-gray-800">Student Details</h2>
            <button onClick={closeModal} className="text-gray-600 hover:text-gray-800 text-2xl">
              &times;
            </button>
          </div>
        </div>
        <div className="p-8">
          {selectedStudent && (
            <div className="space-y-4">
              <div className="flex justify-between">
                <span className="font-semibold text-gray-700">Enrollment Code:</span>
                <span className="text-gray-600">{selectedStudent.enrollment_code}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-semibold text-gray-700">Student Name:</span>
                <span className="text-gray-600">{selectedStudent.student_name}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-semibold text-gray-700">Gender:</span>
                <span className="text-gray-600">{selectedStudent.gender}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-semibold text-gray-700">Father's Name:</span>
                <span className="text-gray-600">{selectedStudent.father_name}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-semibold text-gray-700">Mobile Number 1:</span>
                <span className="text-gray-600">{selectedStudent.mobile_number1}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-semibold text-gray-700">Mobile Number 2:</span>
                <span className="text-gray-600">{selectedStudent.mobile_number2 || 'N/A'}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-semibold text-gray-700">Email:</span>
                <span className="text-gray-600">{selectedStudent.email}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-semibold text-gray-700">Aadhar Number:</span>
                <span className="text-gray-600">{selectedStudent.aadhar_number}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-semibold text-gray-700">State:</span>
                <span className="text-gray-600">{selectedStudent.state}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-semibold text-gray-700">District:</span>
                <span className="text-gray-600">{selectedStudent.district}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-semibold text-gray-700">City:</span>
                <span className="text-gray-600">{selectedStudent.city}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-semibold text-gray-700">Pincode:</span>
                <span className="text-gray-600">{selectedStudent.pincode}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-semibold text-gray-700">Course:</span>
                <span className="text-gray-600">{selectedStudent.course}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-semibold text-gray-700">College:</span>
                <span className="text-gray-600">{selectedStudent.clg_name}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-semibold text-gray-700">Intermediate Percentage/CGPA:</span>
                <span className="text-gray-600">{selectedStudent.inter_percentage}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-semibold text-gray-700">Program Type:</span>
                <span className="text-gray-600">{selectedStudent.program_type}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-semibold text-gray-700">UG/PG Course:</span>
                <span className="text-gray-600">{selectedStudent.ug_pg_course}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-semibold text-gray-700">Call Status:</span>
                <span className="text-gray-600">{selectedStudent.call_status}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-semibold text-gray-700">Remarks:</span>
                <span className="text-gray-600">{selectedStudent.remarks}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-semibold text-gray-700">Status:</span>
                <span
                  className={`${selectedStudent.is_interested === "Most Interested"
                      ? 'font-semibold text-green-600'  // Green for Most Interested
                      : selectedStudent.is_interested === "Interested"
                        ? 'font-semibold text-yellow-500'  // Yellow for Interested
                        : selectedStudent.is_interested === "Not Interested"
                          ? 'font-semibold text-red-600'  // Red for Not Interested
                          : 'text-gray-600'  // Gray for N/A (default)
                    }`}
                >
                  {selectedStudent.is_interested}
                </span>

              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
        </>
      )}
    </div>

  );
};

export default Dashboard;
