import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { AiOutlineClose, AiOutlineUserAdd, AiOutlineEdit, AiOutlineEye } from 'react-icons/ai';
import SummaryApi from '../common'; // Import your API configuration
import { useTable, usePagination } from 'react-table';
import * as XLSX from 'xlsx';
import { IoIosCloseCircle } from "react-icons/io";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const EnrolledStudents = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [studentsData, setStudentsData] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [enrollmentCode, setEnrollmentCode] = useState(null);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('All');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCourses, setSelectedCourses] = useState([]); // Stores selected courses
  const [dropdownValue, setDropdownValue] = useState(''); // Value of selected option
  const reasons = [
    "Wrong Number",
    "Not Reachable",
    "Number Not Working",
    "Call Declined",
    "Busy"
  ];
  const [selectedRange, setSelectedRange] = useState('');  // The selected date range
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [isCustomRange, setIsCustomRange] = useState(false);


  const [formData, setFormData] = useState({
    student_name: '',
    gender: 'Male',
    father_name: '',
    mobile_number1: '',
    mobile_number2: '',
    email: '',
    aadhar_number: '',
    state: '',
    district: '',
    city: '',
    pincode: '',
    course: '',
    clg_name: '',
    program_type: '',
    ug_pg_course: '',
    call_back_reason: '',
    other_reasons: '',
    is_interested: '',
    call_status: '',
    remarks: '',
  });

  const [memberType, setMemberType] = useState(null);
  const [loading, setLoading] = useState(true);

  const headers = {
    'Authorization': 'Bearer sandeep_university_916',
    'Content-Type': 'application/json',
  };
  const fetchStudents = async () => {
    setLoading(true);
    try {
      const encodedMemberType = sessionStorage.getItem('K3M8X1JQ5VZ4B7PWTL9');
      const memberType = encodedMemberType ? atob(encodedMemberType) : null;
      setMemberType(memberType);

      let response;

      if (memberType === 'Admin' || memberType === 'Manager') {
        response = await fetch(SummaryApi.getAllStudents.url, {
          method: SummaryApi.getAllStudents.method,
          headers: {
            'Authorization': 'Bearer sandeep_university_916',
            'Content-Type': 'application/json',
          },
        });
      } else {
        const encodedEmployeeId = sessionStorage.getItem('H7Z4L9VD2XK5JW8QRYT6');
        if (!encodedEmployeeId) {
          throw new Error('Employee ID not found in session storage');
        }
        const decodedEmployeeId = atob(encodedEmployeeId);

        response = await fetch(SummaryApi.getStudentsByEmployeeID.url, {
          method: SummaryApi.getStudentsByEmployeeID.method,
          headers: {
            'Authorization': 'Bearer sandeep_university_916',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ employee_id: decodedEmployeeId }),
        });
      }

      if (!response.ok) {
        throw new Error('Failed to fetch data from the endpoint');
      }

      const data = await response.json();

      const students = data?.data || []

      const updatedStudentsData = students.map((student, index) => ({
        ...student, // Copy the original student object
        sno: index + 1, // Assign the serial number as index + 1
      }));

      setStudentsData(updatedStudentsData || []);
      setFilteredStudents(updatedStudentsData || []);
    } catch (error) {
      toast.error(`Error fetching students: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {


    fetchStudents();
  }, []);

  const handleFilterChange = (filter) => {
    // Set selected filter
    setSelectedFilter(filter);

    // Directly apply the filter to the students data
    filterStudents(filter, searchQuery, selectedRange);
  };

  const filterStudents = (filter, search, range) => {
    let filtered = studentsData;

    // Time zone: Asia/Kolkata
    const timeZone = 'Asia/Kolkata';

    // Helper function to format a date to the Asia/Kolkata timezone
    const formatToKolkata = (date) => {
      return new Intl.DateTimeFormat('en-IN', {
        timeZone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }).format(date);
    };

    // Apply filter based on call_status
    if (filter !== 'All') {
      filtered = filtered.filter((student) => student.call_status === filter);
    }

    // Apply date filters based on selected range
    const convertToKolkataDateString = (date) => formatToKolkata(new Date(date));

    if (range === 'today') {
      const today = new Date();
      const todayFormatted = formatToKolkata(today);

      filtered = filtered.filter((student) => {
        const studentDate = new Date(student.created_date);
        const studentDateFormatted = convertToKolkataDateString(studentDate);
        return studentDateFormatted === todayFormatted;
      });
    } else if (range === 'yesterday') {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      const yesterdayFormatted = formatToKolkata(yesterday);

      filtered = filtered.filter((student) => {
        const studentDate = new Date(student.created_date);
        const studentDateFormatted = convertToKolkataDateString(studentDate);
        return studentDateFormatted === yesterdayFormatted;
      });
    } else if (range === 'thisWeek') {
      const today = new Date();
      const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay())); // start of this week (Sunday)
      const endOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 6)); // end of this week (Saturday)

      const startOfWeekFormatted = formatToKolkata(startOfWeek);
      const endOfWeekFormatted = formatToKolkata(endOfWeek);

      filtered = filtered.filter((student) => {
        const studentDate = new Date(student.created_date);
        const studentDateFormatted = convertToKolkataDateString(studentDate);
        return studentDateFormatted >= startOfWeekFormatted && studentDateFormatted <= endOfWeekFormatted;
      });
    } else if (range === 'lastWeek') {
      const today = new Date();
      const startOfLastWeek = new Date(today.setDate(today.getDate() - today.getDay() - 7)); // start of last week (Sunday)
      const endOfLastWeek = new Date(today.setDate(today.getDate() - today.getDay() - 1)); // end of last week (Saturday)
      const startOfLastWeekFormatted = formatToKolkata(startOfLastWeek);
      const endOfLastWeekFormatted = formatToKolkata(endOfLastWeek);
      filtered = filtered.filter((student) => {
        const studentDate = new Date(student.created_date);
        const studentDateFormatted = convertToKolkataDateString(studentDate);
        return studentDateFormatted >= startOfLastWeekFormatted && studentDateFormatted <= endOfLastWeekFormatted;
      });
    } else if (range === 'custom') {
      if (!fromDate || !toDate) {
        return; // If fromDate or toDate is not provided, don't apply custom range filter
      }

      console.log(fromDate, toDate)

      // Ensure both dates are in 'YYYY-MM-DD' format
      const fromFormatted = formatDate(fromDate)
      const toFormatted = formatDate(toDate)
      console.log(fromFormatted, toFormatted)

      filtered = filtered.filter((student) => {
        const studentDate = new Date(student.created_date);
        const studentDateFormatted = formatDate(studentDate)
        return studentDateFormatted >= fromFormatted && studentDateFormatted <= toFormatted;
      });
    }

    // Search logic based on memberType
    if (['Admin', 'Manager'].includes(memberType) && search) {
      filtered = filtered.filter((student) =>
        student.student_name.toLowerCase().includes(search.toLowerCase()) ||
        student.employee_name.toLowerCase().includes(search.toLowerCase()) ||
        student.mobile_number1.toLowerCase().includes(search.toLowerCase())
      );
    } else if (search) {
      filtered = filtered.filter((student) =>
        student.student_name.toLowerCase().includes(search.toLowerCase()) ||
        student.mobile_number1.toLowerCase().includes(search.toLowerCase())
      );
    }

    setFilteredStudents(filtered);
  };



  const handleRangeChange = (e) => {
    const range = e.target.value;
    setSelectedRange(range);
    setIsCustomRange(range === 'custom');
    if (range != 'custom') {
      setFromDate(null);
      setToDate(null); // Reset custom date range when switching to a predefined range
      filterStudents(selectedFilter, searchQuery, range)
    } else {
      setFilteredStudents([])
    }
  };

  useEffect(() => {
    if (fromDate && toDate) {
      filterStudents(selectedFilter, searchQuery, 'custom');
    }
  }, [fromDate, toDate]);  // Make sure to include both 'fromDate' and 'toDate' as dependencies


  // Handle search input change and apply filter
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value); // Update the search query
    filterStudents(selectedFilter, e.target.value, selectedRange); // Filter students based on search and selected filter
  };


  // Courses based on UG/PG selection
  const ugCourses = ['CSE', 'CSE(AI & ML)', 'CSE(AI & DS)', 'AERONAUTICAL ENGINERRING', 'ECE'];
  const pgCourses = [];

  // Filter the courses based on UG/PG selection
  const courses = formData.program_type === 'UG' ? ugCourses : formData.program_type === 'PG' ? pgCourses : [];

  const openModal = (student = null, mode = 'view') => {
    // Function to replace "N/A" with an empty string in all properties of the student object
    const sanitizeStudentData = (data) => {
      const sanitizedData = { ...data }; // Make a copy of the object to avoid mutating the original
      Object.keys(sanitizedData).forEach((key) => {
        if (sanitizedData[key] === "N/A") {
          sanitizedData[key] = "";
        }
      });
      return sanitizedData;
    };

    const payload = {
      employee_id: student?.employee_id,
      student_name: student?.student_name,
      gender: student?.gender,
      father_name: student?.father_name,
      mobile_number1: student?.mobile_number1,
      mobile_number2: student?.mobile_number2 || '', // Ensure an empty string if not provided
      email: student?.email || '',
      aadhar_number: student?.aadhar_number || '',
      state: student?.state,
      district: student?.district,
      city: student?.city,
      pincode: student?.pincode || '',  // Assuming 'code' is pincode
      course: student?.course,
      clg_name: student?.clg_name,
      inter_percentage: student?.inter_percentage,
      program_type: student?.program_type,
      ug_pg_course: student?.ug_pg_course,
      call_status: student?.call_status || '', // Default to empty string if not provided
      remarks: student?.remarks || '', // Default to empty string if not provided
      is_interested: reasons.includes(student?.is_interested) ? "Others" : student?.is_interested,
      other_reasons: student?.is_interested,
      call_back_reason: student?.call_back_reason,
    };

    // Sanitize the student data
    const sanitizedStudent = sanitizeStudentData(payload);

    if (mode === 'edit') {

      // Set sanitized data
      setFormData(sanitizedStudent);
      setIsUpdate(true);
      setIsUpload(false);

      // Convert the "ug_pg_course" string into an array and set it to selectedCourses
      const coursesArray = sanitizedStudent.ug_pg_course
        ? sanitizedStudent.ug_pg_course.split(',').map(course => course.trim()) // Split and trim each course name
        : []; // If no courses, set an empty array

      setSelectedCourses(coursesArray); // Set the selected courses to the state
    }
    else if (mode === 'add') {
      setFormData({
        student_name: '',
        gender: 'Male',
        father_name: '',
        mobile_number1: '',
        mobile_number2: '',
        email: '',
        aadhar_number: '',
        state: '',
        district: '',
        city: '',
        pincode: '',
        course: '',
        clg_name: '',
        remarks: '',
        call_status: '',
        program_type: '',
        ug_pg_course: '',
        is_interested: '',
      });
      setIsUpdate(false);
      setIsUpload(true);
    } else {
      setFormData(payload);
      setIsUpdate(false);
      setIsUpload(false);
      // Convert the "ug_pg_course" string into an array and set it to selectedCourses
      const coursesArray = sanitizedStudent.ug_pg_course
        ? sanitizedStudent.ug_pg_course.split(',').map(course => course.trim()) // Split and trim each course name
        : []; // If no courses, set an empty array

      setSelectedCourses(coursesArray); // Set the selected courses to the state

    }
    setSelectedStudent(student);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedStudent(null);
    setIsUpdate(false);
    setIsUpload(false);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    // If call_status is 'Not Answered', reset is_interested field
    if (name === 'call_status' && (value === 'Not Answered' || value === '')) {
      setFormData((prev) => ({
        ...prev,
        is_interested: '', // Clears the value of is_interested
        [name]: value, // Update the call_status
      }));
    }
    // If 'is_interested' is changed
    else if (name === 'is_interested') {
      // If value is not 'Call Back', clear call_back_reason
      // If value is not 'Others', clear other_reasons
      setFormData((prev) => ({
        ...prev,
        [name]: value, // Update the is_interested value
        ...(value !== 'Call Back' && { call_back_reason: '' }), // Clear call_back_reason if not 'Call Back'
        ...(value !== 'Others' && { other_reasons: '' }), // Clear other_reasons if not 'Others'
      }));
    } else if (name === 'program_type' && (value === 'PG' || value === '')) {
      setSelectedCourses([]);
      setDropdownValue('');
      setFormData((prev) => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : value, // Update value for checkbox or other types
      }));
    }
    // For other inputs (e.g., checkboxes, radio buttons), just update their values
    else {
      setFormData((prev) => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : value, // Update value for checkbox or other types
      }));
    }
  };

  // Handle dropdown selection
  const handleSelectChange = (e) => {
    const selectedCourse = e.target.value;
    if (selectedCourse && !selectedCourses.includes(selectedCourse)) {
      setSelectedCourses((prev) => [...prev, selectedCourse]); // Add selected course
    }
    setDropdownValue(''); // Clear the dropdown after selection
  };

  // Remove a course tag
  const handleRemoveTag = (course) => {
    setSelectedCourses((prev) => prev.filter(c => c !== course));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingSubmit(true);

    try {
      const encodedEmployeeId = sessionStorage.getItem('H7Z4L9VD2XK5JW8QRYT6');
      if (!encodedEmployeeId) {
        throw new Error('Employee ID not found in session storage');
      }
      const decodedEmployeeId = atob(encodedEmployeeId);

      const apiEndpoint = isUpdate ? SummaryApi.updateStudent.url : SummaryApi.uploadStudent.url;
      const method = isUpdate ? SummaryApi.updateStudent.method : SummaryApi.uploadStudent.method;

      const payloadData = {
        employee_student_id: formData.employee_student_id,
        student_name: formData.student_name,
        last_name: '',
        gender: formData.gender,
        father_name: formData.father_name,
        mobile_number1: formData.mobile_number1,
        mobile_number2: formData.mobile_number2 || '', // Default to empty string if not provided
        email: formData.email || '', // Default to empty string if not provided
        aadhar_number: formData.aadhar_number || '', // Default to empty string if not provided
        state: formData.state,
        district: formData.district,
        city: formData.city,
        pincode: formData.pincode || '', // Default to empty string if not provided
        course: formData.course,
        clg_name: formData.clg_name,
        inter_percentage: formData.inter_percentage || '',
        program_type: formData.program_type,
        ug_pg_course: selectedCourses.join(', '), // Comma-separated string of selected courses
        call_status: formData.call_status || '', // Default to empty string if not provided
        remarks: formData.remarks || '', // Default to empty string if not provided
        is_interested: formData.is_interested === 'Others' ? formData.other_reasons : formData.is_interested, // If "Others", use other_reasons
        call_back_reason: formData.call_back_reason || '', // Default to empty string if not provided
      };

      const payload = isUpdate
        ? {
          ...payloadData,
          student_id: selectedStudent.student_id, // Add student_id for update
          employee_id: decodedEmployeeId, // Add employee_id
        }
        : {
          ...payloadData,
          employee_id: decodedEmployeeId, // Add employee_id for create
        };


      const response = await fetch(apiEndpoint, {
        method: method,
        headers: headers,
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();

      if (result.status) {
        toast.success(isUpdate ? 'Student updated successfully!' : 'Student added successfully!');

        // Set enrollment code if not updating
        if (!isUpdate) {
          setEnrollmentCode(result.ENR_CODE);
        }

        closeModal();
        fetchStudents();
        setSelectedFilter('All')
        setSelectedCourses([])
        setDropdownValue('')
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    } finally {
      setLoadingSubmit(false); // Reset loading state when done
    }
  };

  // // Effect to send WhatsApp message when enrollment code is set
  // useEffect(() => {
  //   const sendWhatsAppMessage = async () => {
  //     if (enrollmentCode) {
  //       await openWhatsAppMessage(formData);
  //     }
  //   };

  //   sendWhatsAppMessage();
  // }, [enrollmentCode]); // Run when enrollmentCode changes

  const downloadExcelReport = () => {
    const requiredFields = [
      'enrollment_code',
      'student_name',
      'email',
      'mobile_number1',
      'mobile_number2',
      'aadhar_number',
      'state',
      'district',
      'city',
      'pincode',
      'course',
      'clg_name',
      'created_date',
      'call_status',
      'remarks',
      'employee_name'
    ];

    const filteredData = studentsData.map(student => {
      const selectedData = {};
      requiredFields.forEach(field => {
        selectedData[field] = student[field];
      });
      return selectedData;
    });

    // Prepare the worksheet with custom headers
    const headers = {
      enrollment_code: 'Enrollment Code',
      student_name: 'Student Name',
      email: 'Email',
      mobile_number1: 'Mobile Number 1',
      mobile_number2: 'Mobile Number 2',
      aadhar_number: 'Aadhar Number',
      state: 'State',
      district: 'District',
      city: 'City',
      pincode: 'Pincode',
      course: 'Course',
      clg_name: 'College',
      created_date: 'Enrolled Date',
      call_status: 'Call Status',
      remarks: 'Remarks',
      employee_name: 'Employee Name'
    };

    const formattedData = filteredData.map(student => {
      return Object.fromEntries(
        Object.entries(student).map(([key, value]) => [headers[key], value])
      );
    });

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Students');

    // Save to file
    XLSX.writeFile(workbook, 'students_report.xlsx');
  };


  const openWhatsAppMessage = (studentData) => {
    const message = `🌟 Hello ${studentData.first_name} ${studentData.last_name}!.%0A
        We're thrilled to welcome you to SANJAY GHODAWAT UNIVERSITY.%0A
        Your enrollment code is ${enrollmentCode}, and your mobile number is ${studentData.mobile_number1}.%0A
        You’ve chosen the ${studentData.course} course under the ${studentData.program_type} program.%0A
        We're excited about your journey with us, and if you have any questions, don’t hesitate to reach out.%0A
        Looking forward to seeing you soon! 🚀%0A
        Best Regards,%0A
        SANJAY GHODAWAT UNIVERSITY.`;
    const phoneNumber = studentData.mobile_number1; // Ensure this is in international format
    const whatsappURL = `https://api.whatsapp.com/send?phone=91${phoneNumber}&text=${message}`;

    // Open in a new tab
    window.open(whatsappURL, '_blank');
  };

  const formatDate = (dateString) => {
    // Parse the date string into a Date object
    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date)) {
      return "NA";
    }

    // Options for formatting the date
    const options = { day: '2-digit', month: 'short', year: 'numeric' };

    // Return the formatted date string
    return date.toLocaleDateString('en-GB', options);
  };

  const columns = React.useMemo(
    () => [
      { Header: 'SNO', accessor: 'sno' },
      ...(['Admin', 'Manager'].includes(memberType) ? [
        {
          Header: 'Employee Name',
          accessor: 'employee_name',
        },
      ] : []),
      {
        Header: 'Enrollment Code',
        accessor: 'enrollment_code',
      },
      {
        Header: 'Student Name',
        accessor: 'student_name',
      },
      {
        Header: 'City',
        accessor: 'city',
      },
      {
        Header: 'Mobile Number',
        accessor: 'mobile_number1',
      },
      {
        Header: 'Course',
        accessor: 'course',
      },
      {
        Header: 'Enrolled Date',
        accessor: 'enroll_date',
        Cell: ({ row }) => {
          const { created_date } = row.original;

          return <span>{formatDate(created_date)}</span>;
        },
      },
      {
        Header: 'Status',
        accessor: 'is_interested',
        Cell: ({ value }) => {
          let colorClass = 'text-gray-600'; // Default to red (Not Interested)
          if (value === 'Most Interested') {
            colorClass = 'text-green-600'; // Green for Most Interested
          } else if (value === 'Interested') {
            colorClass = 'text-yellow-500'; // Yellow for Interested
          } else if (value === 'Not Interested') {
            colorClass = 'text-red-500'; // Yellow for Interested
          }

          return (
            <span className={`font-semibold ${colorClass}`}>
              {value}
            </span>
          );
        },

      },
      ...(['Employee', 'Consultant'].includes(memberType) ? [{
        Header: 'Edit',
        accessor: 'edit',
        Cell: ({ row }) => (
          <button
            className={`bg-green-600 text-white px-3 py-2 rounded-md transition-transform transform hover:scale-105`}
            onClick={() => openModal(row.original, 'edit')}
          >
            <AiOutlineEdit className="inline-block mr-1" />
            Edit
          </button>
        ),
      }] : []),
      {
        Header: 'View More',
        accessor: 'view',
        Cell: ({ row }) => (
          <button
            className="bg-yellow-600 text-white px-3 py-2 rounded-md transition-transform transform hover:scale-105"
            onClick={() => openModal(row.original, 'view')}
          >
            <AiOutlineEye className="inline-block mr-1" />
            View More
          </button>
        ),
      },
    ],
    [memberType] // Ensure columns re-render on memberType change
  );


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { pageIndex, pageSize },
    page,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    pageCount,
  } = useTable({
    columns,
    data: filteredStudents,
    initialState: { pageIndex: 0 },
  }, usePagination);

  // console.log(formData)

  // Clear all filters
  const clearFilters = () => {
    setSelectedRange('')
    setFromDate(null);
    setToDate(null);
    setIsCustomRange(false)
    setFilteredStudents(studentsData)
    setSelectedFilter("All")
    setSearchQuery('')
  };

  return (

    <div className="container mx-auto p-6">
      {loading ? (
        <div className="fixed inset-0 bg-black bg-opacity-70 z-20 flex justify-center items-center">
          <span className="text-white text-lg">Fetching Data...</span>
        </div>
      ) : (
        <>
          <div className="container mx-auto p-4">
            {loadingSubmit && (
              <div className="fixed inset-0 bg-black bg-opacity-70 z-20 flex justify-center items-center">
                <span className="text-white text-lg">Submitting...</span>
              </div>
            )}

            <div className='flex justify-between mb-6'>
              <h1 className="text-3xl font-bold text-center">Total Enrolled Students</h1>
              <div>
                {['Employee', 'Consultant'].includes(memberType) && ( // Conditional rendering
                  <button
                    className={`bg-blue-600 text-white text-lg px-4 py-2 rounded mb-2 md:mb-0`}
                    onClick={() => openModal(null, 'add')}
                    disabled={loading}
                  >
                    <AiOutlineUserAdd className="inline-block mr-2" />
                    Enroll New Student
                  </button>
                )}

                {/* <button
            className={`bg-green-500 text-white text-lg px-4 py-2 rounded ml-0 md:ml-4 ${loading ? 'cursor-not-allowed opacity-50' : ''}`}
            onClick={downloadExcelReport}
            disabled={loading}
          >
            Download Excel Report
          </button> */}
              </div>
            </div>

            <div>


              <div className='flex justify-between'>
                <div>
                  <button className={`py-2 px-4 rounded-lg text-white ${selectedFilter === 'All' ? 'bg-gray-500 font-semibold' : 'bg-blue-500'}`} onClick={() => handleFilterChange('All')}>All</button>
                  <button className={`py-2 px-4 rounded-lg text-white ml-6 ${selectedFilter === 'Contacted' ? 'bg-gray-500 font-semibold' : 'bg-blue-500'}`} onClick={() => handleFilterChange('Contacted')}>Contacted</button>
                  <button className={`py-2 px-4 rounded-lg text-white ml-6 ${selectedFilter === 'Not Answered' ? 'bg-gray-500 font-semibold' : 'bg-blue-500'}`} onClick={() => handleFilterChange('Not Answered')}>Not Answered</button>
                  {/* <button className={`py-2 px-4 rounded-lg text-white ml-6 ${selectedFilter === 'Interested' ? 'bg-gray-500 font-semibold' : 'bg-blue-500'}`} onClick={() => handleFilterChange('Interested')}>Interested</button> */}
              
              
               </div>

                {['Admin', 'Manager'].includes(memberType) && ( // Conditional rendering
                  <div>
                    <label htmlFor="dateRange">Select Date Range:</label>
                    <select id="dateRange" className='bg-blue-500 py-2 px-4 text-white rounded-lg ml-4' onChange={handleRangeChange} value={selectedRange}>
                      <option value="">Select a range</option>
                      <option value="today">Today</option>
                      <option value="yesterday">Yesterday</option>
                      <option value="thisWeek">This Week</option>
                      <option value="lastWeek">Last Week</option>
                      <option value="custom">Custom</option>
                    </select>

                    <button
                      onClick={clearFilters}
                      className="px-4 py-2 ml-4 bg-red-500 text-white rounded-lg hover:bg-red-600 focus:outline-none"
                    >
                      Clear Filters
                    </button>

                    {isCustomRange && (
                      <div className='flex mt-4 gap-4'>
                        <div>
                          <label>From:</label>
                          <DatePicker
                            selected={fromDate}
                            onChange={(date) => setFromDate(date)}
                            selectsStart
                            startDate={fromDate}
                            endDate={toDate}
                            maxDate={toDate}
                            dateFormat="yyyy-MM-dd"
                            placeholderText="Select From Date"
                            className='p-2 ml-2 border rounded-lg'
                          />
                        </div>

                        <div>
                          <label>To:</label>
                          <DatePicker
                            selected={toDate}
                            onChange={(date) => setToDate(date)}
                            selectsEnd
                            startDate={fromDate}
                            endDate={toDate}
                            minDate={fromDate}
                            dateFormat="yyyy-MM-dd"
                            placeholderText="Select To Date"
                            className='p-2 ml-2 border rounded-lg'
                            disabled={!fromDate}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}

              </div>

              {/* Search Input */}
              <div className='w-1/4 mt-4'>
                <input
                  type="text"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  placeholder={['Admin', 'Manager'].includes(memberType) ? "Search by student name or mobile number or employee name" : "Search by student name or mobile number"}
                  className="py-2 px-4 border rounded-lg w-full"
                />
              </div>
            </div>

            <div className="overflow-x-auto p-4">
              <table {...getTableProps()} className="min-w-full mb-4 rounded-lg shadow-md overflow-hidden">
                <thead className="bg-gray-200">
                  {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps()} className="px-4 py-2 text-left text-gray-700 font-semibold">
                          {column.render('Header')}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map(row => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} className="hover:bg-gray-100 transition duration-200">
                        {row.cells.map(cell => (
                          <td {...cell.getCellProps()} className="border px-4 py-2">
                            {cell.render('Cell')}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <div className="flex justify-between items-center mb-4">
                <span>
                  Page {pageIndex + 1} of {pageCount} of {filteredStudents.length} entries
                </span>
                <div>
                  <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className="px-3 py-1 border rounded">{"<<"}</button>
                  <button onClick={previousPage} disabled={!canPreviousPage} className="px-3 py-1 border rounded">{"<"}</button>
                  <button onClick={nextPage} disabled={!canNextPage} className="px-3 py-1 border rounded">{">"}</button>
                  <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} className="px-3 py-1 border rounded">{">>"}</button>
                </div>
                <select value={pageSize} onChange={e => {
                  setPageSize(Number(e.target.value));
                }} className="ml-4 border rounded">
                  {[5, 10, 20, 50, 100].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              contentLabel="Student Details"
              className="mx-auto my-10 mt-[100px] max-h-[85vh] max-w-full sm:max-w-4xl bg-white rounded-lg shadow-lg overflow-auto"
              overlayClassName="fixed inset-0 bg-black bg-opacity-50"
            >

              <div className="sticky top-0 z-10 bg-white px-8 py-4">
                <div className="flex justify-between items-center">
                  <h2 className="text-2xl font-semibold">{isUpdate ? 'Edit Student' : isUpload ? 'Add Student' : 'Student Details'}</h2>
                  <div>
                    {
                      !(isUpdate || isUpload) && (
                        <button
                          className={`px-4 py-2 rounded-lg text-white mr-4 ${formData.is_interested !== "Most Interested" ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'}`}
                          disabled={formData.is_interested !== "Most Interested"}
                        >
                          Send Mail
                        </button>
                      )
                    }

                    {
                      !(isUpdate || isUpload) && (
                        <button
                          className={`px-4 py-2 rounded-lg text-white mr-4 ${formData.is_interested !== "Most Interested" ? 'bg-gray-300 cursor-not-allowed' : 'bg-green-500 hover:bg-green-600'}`}
                          disabled={formData.is_interested !== "Most Interested"}
                        >
                          Send WhatsApp Message
                        </button>
                      )
                    }

                    <button onClick={closeModal}>
                      <AiOutlineClose className="w-6 h-6 text-gray-600 hover:text-gray-800 transition duration-200" />
                    </button>
                  </div>
                </div>
              </div>

              <form onSubmit={handleSubmit} className="grid grid-cols-1 sm:grid-cols-2 gap-4 p-4 sm:p-8">
                <div className="flex flex-col">
                  <label htmlFor="student_name" className="flex items-center">Student Name<span className='ml-1 text-red-600'>*</span></label>
                  <input
                    type="text"
                    id="student_name"
                    name="student_name"
                    value={formData.student_name}
                    onChange={handleChange}
                    className={`p-2 bg-slate-100 border rounded ${!(isUpdate || isUpload) && 'cursor-not-allowed'}`}
                    disabled={!(isUpdate || isUpload)}
                    required
                  />
                </div>

                <div className="flex flex-col">
                  <label htmlFor="gender" className="flex items-center">Gender</label>
                  <select
                    id="gender"
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                    className={`p-2 bg-slate-100 border rounded ${!(isUpdate || isUpload) && 'cursor-not-allowed'}`}
                    disabled={!(isUpdate || isUpload)}
                  >
                    <option value="">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>

                <div className="flex flex-col">
                  <label htmlFor="father_name" className="flex items-center">Father Name</label>
                  <input
                    type="text"
                    id="father_name"
                    name="father_name"
                    value={formData.father_name}
                    onChange={handleChange}
                    className={`p-2 bg-slate-100 border rounded ${!(isUpdate || isUpload) && 'cursor-not-allowed'}`}
                    disabled={!(isUpdate || isUpload)}
                  />
                </div>

                <div className="flex flex-col">
                  <label htmlFor="mobile_number1" className="flex items-center">Mobile Number 1<span className='ml-1 text-red-600'>*</span></label>
                  <input
                    type="tel"
                    id="mobile_number1"
                    name="mobile_number1"
                    value={formData.mobile_number1}
                    onChange={(e) => {
                      const value = e.target.value;

                      // Allow only digits and restrict to 10 digits
                      if (/^\d*$/.test(value) && value.length <= 10) {
                        setFormData((prev) => ({
                          ...prev,
                          mobile_number1: value,
                        }));
                      }
                    }}
                    className={`p-2 bg-slate-100 border rounded ${!(isUpdate || isUpload) && 'cursor-not-allowed'}`}
                    disabled={!(isUpdate || isUpload)}
                    required
                    pattern="\d{10}"
                    title="Mobile number must be exactly 10 digits."
                  />
                </div>

                <div className="flex flex-col">
                  <label htmlFor="mobile_number2" className="flex items-center">Mobile Number 2:</label>
                  <input
                    type="tel"
                    id="mobile_number2"
                    name="mobile_number2"
                    value={formData.mobile_number2}
                    onChange={(e) => {
                      const value = e.target.value;

                      // Allow only digits and restrict to 10 digits
                      if (/^\d*$/.test(value) && value.length <= 10) {
                        setFormData((prev) => ({
                          ...prev,
                          mobile_number2: value,
                        }));
                      }
                    }}
                    className={`p-2 bg-slate-100 border rounded ${!(isUpdate || isUpload) && 'cursor-not-allowed'}`}
                    disabled={!(isUpdate || isUpload)}
                    pattern="\d{10}"
                    title="Mobile number must be exactly 10 digits."
                  />
                </div>

                <div className="flex flex-col">
                  <label htmlFor="email" className="flex items-center">Email:</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className={`p-2 bg-slate-100 border rounded ${!(isUpdate || isUpload) && 'cursor-not-allowed'}`}
                    disabled={!(isUpdate || isUpload)}

                  />
                </div>

                <div className="flex flex-col">
                  <label htmlFor="aadhar_number" className="flex items-center">Aadhar Number:</label>
                  <input
                    type="text"
                    id="aadhar_number"
                    name="aadhar_number"
                    value={formData.aadhar_number}
                    onChange={(e) => {
                      const rawValue = e.target.value.replace(/-/g, '');
                      const formattedValue = rawValue
                        .replace(/\D/g, '')
                        .replace(/(\d{4})(?=\d)/g, '$1-');

                      if (formattedValue.length <= 14) {
                        setFormData((prev) => ({
                          ...prev,
                          aadhar_number: formattedValue,
                        }));
                      }
                    }}
                    className={`p-2 bg-slate-100 border rounded ${!(isUpdate || isUpload) && 'cursor-not-allowed'}`}
                    disabled={!(isUpdate || isUpload)}
                    pattern="(\d{4}-\d{4}-\d{4})"
                    title="Aadhar number must be in the format 1234-5678-9012."
                  />
                </div>

                <div className="flex flex-col">
                  <label htmlFor="state" className="flex items-center">State</label>
                  <input
                    type="text"
                    id="state"
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                    className={`p-2 bg-slate-100 border rounded ${!(isUpdate || isUpload) && 'cursor-not-allowed'}`}
                    disabled={!(isUpdate || isUpload)}

                  />
                </div>

                <div className="flex flex-col">
                  <label htmlFor="district" className="flex items-center">District</label>
                  <input
                    type="text"
                    id="district"
                    name="district"
                    value={formData.district}
                    onChange={handleChange}
                    className={`p-2 bg-slate-100 border rounded ${!(isUpdate || isUpload) && 'cursor-not-allowed'}`}
                    disabled={!(isUpdate || isUpload)}

                  />
                </div>

                <div className="flex flex-col">
                  <label htmlFor="city" className="flex items-center">City:</label>
                  <input
                    type="text"
                    id="city"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    className={`p-2 bg-slate-100 border rounded ${!(isUpdate || isUpload) && 'cursor-not-allowed'}`}
                    disabled={!(isUpdate || isUpload)}

                  />
                </div>

                <div className="flex flex-col">
                  <label htmlFor="pincode" className="flex items-center">Pincode:</label>
                  <input
                    type="text"
                    id="pincode"
                    name="pincode"
                    value={formData.pincode}
                    onChange={(e) => {
                      const value = e.target.value;

                      // Allow only digits and restrict to 6 digits
                      if (/^\d*$/.test(value) && value.length <= 6) {
                        setFormData((prev) => ({
                          ...prev,
                          pincode: value,
                        }));
                      }
                    }}
                    className={`p-2 bg-slate-100 border rounded ${!(isUpdate || isUpload) && 'cursor-not-allowed'}`}
                    disabled={!(isUpdate || isUpload)}
                    pattern="\d{6}"
                    title="Pincode must be exactly 6 digits."
                  />
                </div>

                <div className="flex flex-col">
                  <label htmlFor="course" className="flex items-center">Course</label>
                  <input
                    type="text"
                    id="course"
                    name="course"
                    value={formData.course}
                    onChange={handleChange}
                    className={`p-2 bg-slate-100 border rounded ${!(isUpdate || isUpload) && 'cursor-not-allowed'}`}
                    disabled={!(isUpdate || isUpload)}

                  />
                </div>

                <div className="flex flex-col">
                  <label htmlFor="clg_name" className="flex items-center">College</label>
                  <input
                    type="text"
                    id="clg_name"
                    name="clg_name"
                    value={formData.clg_name}
                    onChange={handleChange}
                    className={`p-2 bg-slate-100 border rounded ${!(isUpdate || isUpload) && 'cursor-not-allowed'}`}
                    disabled={!(isUpdate || isUpload)}

                  />
                </div>

                <div className="flex flex-col">
                  <label htmlFor="inter_percentage" className="flex items-center">Intermediate Percentage/CGPA</label>
                  <input
                    type="text"
                    id="inter_percentage"
                    name="inter_percentage"
                    value={formData.inter_percentage}
                    onChange={(e) => {
                      // Only allow numbers, decimals, or percentages
                      const value = e.target.value;
                      const regex = /^(\d+(\.\d*)?|\.\d+)?$/;  // Regex for decimal or percentage (e.g., 90, 90.5, 75%)

                      // If the value matches the regex or is empty, update the state
                      if (regex.test(value) || value === '') {
                        setFormData((prev) => ({
                          ...prev,
                          inter_percentage: value,  // Update with valid input
                        }));
                      }
                    }}
                    className={`p-2 bg-slate-100 border rounded ${!(isUpdate || isUpload) && 'cursor-not-allowed'}`}
                    disabled={!(isUpdate || isUpload)}
                  />
                </div>

                <div className="flex flex-col">
                  <label htmlFor="program_type" className="flex items-center">Program Type</label>
                  <select
                    id="program_type"
                    name="program_type"
                    value={formData.program_type}
                    onChange={handleChange}
                    className={`p-2 bg-slate-100 border rounded ${!(isUpdate || isUpload) && 'cursor-not-allowed'}`}
                    disabled={!(isUpdate || isUpload)}
                  >
                    <option value="">Select Program Type</option>
                    <option value="UG">UG</option>
                    <option value="PG">PG</option>
                  </select>
                </div>

                <div className="flex flex-col">
                  <label htmlFor="ug_pg_course" className="flex items-center">UG/PG Course</label>

                  {/* Dropdown for selecting courses */}
                  <select
                    id="ug_pg_course"
                    name="ug_pg_course"
                    value={dropdownValue}
                    onChange={handleSelectChange}
                    className={`p-2 bg-slate-100 border rounded ${!(isUpdate || isUpload) && 'cursor-not-allowed'}`}
                    disabled={!(isUpdate || isUpload)}
                  >
                    <option value="">Select Course</option>
                    {courses.map((course) => (
                      <option key={course} value={course}>{course}</option>
                    ))}
                  </select>

                  {/* Display selected courses as tags */}
                  <div className="flex flex-wrap gap-2 mt-2">
                    {selectedCourses.map((course) => (
                      <div key={course} className="flex items-center bg-blue-100 rounded-full px-3 py-1">
                        <span>{course}</span>
                        <button
                          type="button"
                          className="ml-2 text-red-500"
                          onClick={() => handleRemoveTag(course)}
                        >
                          <IoIosCloseCircle className='text-blue-800 w-5 h-5' />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Call Status Field */}
                <div className="flex flex-col col-span-1 sm:col-span-2">
                  <label htmlFor="call_status" className="flex items-center">Call Status<span className='ml-1 text-red-600'>*</span></label>
                  <select
                    id="call_status"
                    name="call_status"
                    value={formData.call_status}
                    onChange={handleChange}
                    className={`p-2 bg-slate-100 border rounded ${!(isUpdate || isUpload) && 'cursor-not-allowed'}`}
                    disabled={!(isUpdate || isUpload)}
                    required
                  >
                    <option value="">Select Status</option>
                    <option value="Contacted">Contacted</option>
                    <option value="Not Answered">Not Answered</option>
                  </select>
                </div>

                {/* Remarks Field */}
                <div className="flex flex-col col-span-1 sm:col-span-2">
                  <label htmlFor="remarks" className="flex items-center">Remarks:</label>
                  <textarea
                    id="remarks"
                    name="remarks"
                    value={formData.remarks}
                    onChange={handleChange}
                    className={`p-2 bg-slate-100 border rounded ${!(isUpdate || isUpload) && 'cursor-not-allowed'}`}
                    disabled={!(isUpdate || isUpload)}
                    rows={2}
                  />
                </div>

                <div className="flex flex-col col-span-1 sm:col-span-2">
                  {/* Existing Radio Buttons */}
                  <div className="flex">
                    <label className="flex items-center mr-4">
                      <input
                        type="radio"
                        name="is_interested"
                        value="Not Interested"
                        checked={formData.is_interested === 'Not Interested'}
                        onChange={handleChange}
                        className="mr-2"
                        disabled={formData.call_status === 'Not Answered' || formData.call_status === '' || !(isUpdate || isUpload)}
                      />
                      Not Interested
                    </label>
                    <label className="flex items-center mr-4">
                      <input
                        type="radio"
                        name="is_interested"
                        value="Interested"
                        checked={formData.is_interested === 'Interested'}
                        onChange={handleChange}
                        className="mr-2"
                        disabled={formData.call_status === 'Not Answered' || formData.call_status === '' || !(isUpdate || isUpload)}
                      />
                      Interested
                    </label>
                    <label className="flex items-center mr-4">
                      <input
                        type="radio"
                        name="is_interested"
                        value="Most Interested"
                        checked={formData.is_interested === 'Most Interested'}
                        onChange={handleChange}
                        className="mr-2"
                        disabled={formData.call_status === 'Not Answered' || formData.call_status === '' || !(isUpdate || isUpload)}
                      />
                      Most Interested
                    </label>


                    <label className="flex items-center mr-4">
                      <input
                        type="radio"
                        name="is_interested"
                        value="Call Back"
                        checked={formData.is_interested === 'Call Back'}
                        onChange={handleChange}
                        className="mr-2"
                        disabled={formData.call_status === 'Not Answered' || formData.call_status === '' || !(isUpdate || isUpload)}
                      />
                      Call Back
                    </label>

                    <label className="flex items-center mr-4">
                      <input
                        type="radio"
                        name="is_interested"
                        value="Others"
                        checked={formData.is_interested === 'Others'}
                        onChange={handleChange}
                        className="mr-2"
                        disabled={formData.call_status === 'Not Answered' || formData.call_status === '' || !(isUpdate || isUpload)}
                      />
                      Others
                    </label>


                  </div>

                  <div className="flex items-center mt-4">


                    {/* Display Dropdown when Call Back is selected */}
                    {formData.is_interested === 'Call Back' && (
                      <div className="mt-2 flex items-center justify-center">
                        <label htmlFor="call_back_reason" className="block mb-2">Call Back Reason<span className='ml-1 text-red-600'>*</span>:</label>
                        <select
                          id="call_back_reason"
                          name="call_back_reason"
                          value={formData.call_back_reason || ''}
                          onChange={handleChange}
                          className="p-2 border ml-4 border-gray-300 rounded"
                          required={formData.is_interested === 'Call Back'}
                        >
                          <option value="">Select Reason</option>
                          <option value="IPE Exams">IPE Exams</option>
                          <option value="Jee Mains Results">JEE Mains Results</option>
                          <option value="Eamcet Results">EAMCET Results</option>
                          <option value="IPE Results">IPE Results</option>
                          <option value="Supply Exams">Supply Exams</option>
                          <option value="Supply Results">Supply Results</option>
                          <option value="EOD">EOD</option>
                        </select>
                      </div>
                    )}

                    {/* Display Dropdown when 'Others' is selected */}
                    {formData.is_interested === 'Others' && (
                      <div className="mt-2 flex items-center justify-center">
                        <label htmlFor="other_reasons" className="block mb-2">Other Reason<span className='ml-1 text-red-600'>*</span>:</label>
                        <select
                          id="other_reasons"
                          name="other_reasons"
                          value={formData.other_reasons || ''}
                          onChange={handleChange}
                          className="p-2 border ml-4 border-gray-300 rounded"
                          required={formData.is_interested === 'Others'}
                        >
                          <option value="">Select Reason</option>
                          <option value="Wrong Number">Wrong Number</option>
                          <option value="Not Reachable">Not Reachable</option>
                          <option value="Number Not Working">Number Not Working</option>
                          <option value="Call Declined">Call Declined</option>
                          <option value="Busy">Busy</option>
                          <option value="Switched Off">Switched Off</option>
                          <option value="Incoming Calls Stopped">Incoming Calls Stopped</option>
                        </select>
                      </div>
                    )}
                  </div>
                </div>

                {(isUpdate || isUpload) && (
                  <div className="flex flex-col col-span-1 sm:col-span-2">
                    <button
                      type="submit"
                      className={`bg-blue-600 text-white px-4 py-2 rounded ${!(isUpdate || isUpload) && 'cursor-not-allowed'} ${loadingSubmit && 'cursor-not-allowed'}`}
                      disabled={!(isUpdate || isUpload) || loadingSubmit}
                    >
                      {loadingSubmit ? 'Submitting...' : (isUpdate ? 'Update Student' : 'Add Student')}
                    </button>
                  </div>
                )}

              </form>
            </Modal>
          </div>
        </>
      )}
    </div>
  );
};

export default EnrolledStudents;