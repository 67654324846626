import React, { useRef, useState } from 'react';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import SummaryApi from '../common';

const ExcelUploadPage = () => {
  const [recordLimit, setRecordLimit] = useState(null);
  const [fileRowCount, setFileRowCount] = useState(null);
  const [file, setFile] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);

  // Handles file input changes
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
      if (fileExtension !== 'xlsx' && fileExtension !== 'xls') {
        setError('Please upload a valid Excel file.');
        setFile(null);
        return;
      }

      setError('');
      setFile(selectedFile);
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        setFileRowCount(jsonData.length)

        // If the number of rows exceeds the record limit, slice the data
        if (recordLimit && jsonData.length > recordLimit) {
          setError(`The file exceeds the record limit of ${recordLimit} rows.`);
          setFileData(null); // Reset file data
        } else {
          setFileData(jsonData); // Save parsed Excel data
        }
      };
      reader.readAsArrayBuffer(selectedFile);
    }
  };

  // Handles the record limit selection
  const handleLimitChange = (e) => {
    setRecordLimit(Number(e.target.value));
    setFileData(null); // Reset file data when limit is changed
    setError(''); // Clear any previous error
  };

  // Handles form submission
  const handleSubmit = async () => {
    if (!recordLimit) {
      setError('Please select a record limit.');
      return;
    }

    if (!file) {
      setError('Please upload an Excel file.');
      return;
    }

    if (fileRowCount > recordLimit) {
      setError(`You cannot upload more than ${recordLimit} records.`);
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append('excel_file', file);

    try {
      const response = await fetch(SummaryApi.excelUpload.url, {
        method: SummaryApi.excelUpload.method,
        headers: {
          'Authorization': 'Bearer sandeep_university_916',
        },
        body: formData,
      });

      const data = await response.json();
      if (data.status) {
        toast.success(data.message);
        // Clear the form fields after successful upload
        setFile(null);        // Clear the file input
        setRecordLimit(null); // Reset the record limit
        setFileData(null);    // Clear file data (table preview)
        setError('');         // Clear any error messages

        if (fileInputRef.current) {
          fileInputRef.current.value = ''; // Clear the file input using ref
        }
      } else {
        setError(data.message || 'Error uploading file. Please try again.');
      }
    } catch (error) {
      setError('Server error. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-[89vh] flex items-center justify-center bg-gray-100 py-10">
      <div className="w-full max-w-lg bg-white p-8 rounded-lg shadow-lg">
        <h1 className="text-2xl font-semibold text-center text-gray-700 mb-6">Upload Excel File</h1>

        {/* Record Limit Selection */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-600">Select Record Limit</label>
          <select
            className="mt-2 block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
            onChange={handleLimitChange}
            value={recordLimit || ''}
          >
            <option value="">Select Limit</option>
            <option value={10000}>10,000</option>
            <option value={20000}>20,000</option>
            <option value={30000}>30,000</option>
            <option value={50000}>50,000</option>
          </select>
        </div>

        {/* File Upload */}
        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-600">Upload Excel File</label>
          <input
            ref={fileInputRef} 
            type="file"
            accept=".xlsx,.xls"
            className="mt-2 block w-full p-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
            onChange={handleFileChange}
          />
        </div>

        {fileRowCount && 
          <div className='font-bold text-lg mb-4'>Records Count: 
            <span className='text-green-700 ml-2'>{fileRowCount}</span>
          </div>
        }

        {/* File Preview (Table) */}
        {fileData && (
          <div className="mb-6">
            <h3 className="text-lg font-medium text-gray-700">Preview (first 5 records)</h3>
            <div className="overflow-x-auto">
              <table className="min-w-full table-auto border-collapse border border-gray-300">
                <thead>
                  <tr className="bg-gray-100">
                    {Object.keys(fileData[0]).map((key) => (
                      <th key={key} className="px-4 py-2 border-b text-left text-sm font-medium text-gray-700">
                        {key}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {fileData.slice(0, 5).map((row, index) => (
                    <tr key={index} className="bg-white">
                      {Object.keys(row).map((key) => (
                        <td key={key} className="px-4 py-2 border-b text-sm text-gray-700">
                          {row[key]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {/* Error Message */}
        {error && <p className="text-red-500 text-sm mb-4">{error}</p>}

        {/* Submit Button */}
        <button
          onClick={handleSubmit}
          className={`w-full py-2 px-4 rounded-lg text-white ${
            loading ? 'bg-gray-400' : 'bg-blue-600 hover:bg-blue-700'
          } focus:outline-none focus:ring-2 focus:ring-blue-500`}
          disabled={loading}
        >
          {loading ? 'Uploading...' : 'Upload Data'}
        </button>
      </div>
    </div>
  );
};

export default ExcelUploadPage;
